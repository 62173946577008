/*
 * @Author: liyu
 * @Date: 2022-05-23 11:43:14
 * @LastEditTime: 2022-08-17 11:44:21
 * @Description: file content
 * @FilePath: /sensors-www/src/pages/solutions/automotive/index.tsx
 */
import React from 'react'
import classnames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import Seo from 'components/seo'
import Layout from 'layouts/zh'
import SectionSubstance, { SectionSubstanceBody } from 'components/IterativeComponent/SectionSubstance'
import SectionSwiperMobile from 'components/IterativeComponent/SectionSwiperMobile'
import MousewheelSwiper from 'components/IterativeComponent/MousewheelSwiper'
import Banner from 'components/Banner'
import Button from 'components/Base/Button'
import ProductCard from 'components/IterativeComponent/ProductCard'
import PartnerCard from 'components/PartnerCard'
import CardList from 'components/IterativeComponent/CardList'
import CargoCard from 'components/IterativeComponent/CargoCard'
import CourseCard from 'components/IterativeComponent/CourseCard'
import Panorama from 'components/IterativeComponent/Panorama'
import ScanCodeConsultButton from 'components/ScanCodeConsultButton'
import ArticleFooter from 'components/IterativeComponent/ArticleFooter'
import { Languages } from 'i18n/config'

import * as styles from './index.module.less'
import { Item } from 'rc-menu'

const AutomotiveNew: React.FC = (props) => {
  const demoUrl = ''
  const paradeUrl = ''
  const { automotiveNewYaml } = useStaticQuery(graphql`
    query {
      automotiveNewYaml {
        title
        description
        keywords
        saTitle
        banner_title
        banner_titleMb
        banner_desc
        banner_btnText
        banner_btnHref
        banner_imgUrl
        banner_imgUrl_icon
        banner_imgUrl_mb
        section_two_operationTitle
        section_two_operationDesc
        section_two_qrcodeButtonText
        section_two_qrcodeDesc
        section_two_dataList {
          dataTitle
          buttonText
          buttonHref
          imgUrl
          descList
        }
        software_title
        software_titleMb
        software_sectionCardList {
          cover
          title
          desc
        }
        software_buttonText
        software_qrcode
        tools_title
        tools_title_mb
        tools_thumb
        tools_buttonText
        tools_buttonLink
        tools_descList {
          value
          list
        }
        panorama_title
        panorama_titleMb
        panorama_desc
        panorama_btnText
        panorama_btnCode
        panorama_imgUrl
        panorama_btnText_two
        panorama_btnText_two_url
        partner_title
        partner_titleMb
        partner_desc
        partner_buttonText
        partner_qrcode
        partner_imgBottomText
        partner_voice_imgUrl
        partner_voice_imgUrlMb
        partner_voice_icon_imgUrlMb
        partner_dataList {
          imgUrl
        }
        cargo_title
        cargo_titleMb
        cargo_dataList {
          dataTitle
          btnText
          btnHref
          imgUrl
          imgUrlMb
        }
        product_title
        product_titleMb
        product_dataList {
          dataMsg
          dataTitle
          btnText
          dataDsc
          btnHref
          imgUrl
          imgUrlMb
        }
        digitization_title
        digitization_desc
        digitization_btnText
        digitization_btnHref
        digitization_orderHref
        digitization_orderText
      }
    }
  `)
  return (
    <Layout {...props}>
      <AutomotiveNewState demoUrl={demoUrl} paradeUrl={paradeUrl} automotiveNewYaml={automotiveNewYaml} />
    </Layout>
  )
}

interface AutomotiveNewStateProps {
  demoUrl?: string
  paradeUrl?: string
  lang?: Languages
  automotiveNewYaml: any
}
export const AutomotiveNewState: React.FC<AutomotiveNewStateProps> = (props) => {
  const { demoUrl, paradeUrl, lang, automotiveNewYaml } = props

  return (
    <>
      <Seo
        title={automotiveNewYaml?.title}
        description={automotiveNewYaml?.description}
        keywords={automotiveNewYaml?.keywords}
        saTitle={automotiveNewYaml?.saTitle}
      />
      <main className={classnames(styles.AutomotiveNew, 'pt-[4rem] lg:pt-[64px]')}>
        {/* section 1 */}
        <Banner
          slotTag={
            <div
              className={classnames(
                styles.BannerSlot,
                'absolute hidden lg:block top-[252px] right-[314px] w-[260px]  h-[20px] text-[16px]',
              )}
            >
              高端机油升级，点击可享优惠抵减
            </div>
          }
          className={styles.Banner}
          bannerImg={automotiveNewYaml?.banner_imgUrl}
          bannerImgMb={automotiveNewYaml?.banner_imgUrl_mb}
          bannerIcon={automotiveNewYaml?.banner_imgUrl_icon}
          title={automotiveNewYaml?.banner_title}
          desc={automotiveNewYaml?.banner_desc}
          btnText={automotiveNewYaml?.banner_btnText}
          btnHref={automotiveNewYaml?.banner_btnHref}
          bannerBackground="#000"
          titleMb={automotiveNewYaml?.banner_titleMb}
        />
        {/* section 2 */}
        <SectionSubstance
          style={{ paddingBottom: 0 }}
          className={classnames('relative z-10')}
          title={automotiveNewYaml?.section_two_operationTitle}
          titleMb={automotiveNewYaml?.section_two_operationTitle}
          descText={automotiveNewYaml?.section_two_operationDesc}
        >
          <MousewheelSwiper className="hidden lg:block" dataSource={automotiveNewYaml?.section_two_dataList} />
          <SectionSwiperMobile
            className="block lg:hidden mt-[2rem]"
            dataSource={automotiveNewYaml?.section_two_dataList}
            slotButton={
              <Button btnType="primary" href={automotiveNewYaml?.banner_btnHref} target="_blank">
                {automotiveNewYaml?.section_two_qrcodeDesc}
              </Button>
            }
          />
        </SectionSubstance>
        {/* section 3 */}
        <SectionSubstance title={automotiveNewYaml?.software_title} titleMb={automotiveNewYaml?.software_titleMb}>
          <SectionSubstanceBody>
            <CardList
              isHeight={false}
              cardClassName={styles.Card}
              titleColor="#1F2D3D"
              coverWidth={120}
              coverHeight={130}
              autoplay
              yGap={60}
              xGap={32}
              mYGap={20}
              mXGap={0}
              datas={automotiveNewYaml?.software_sectionCardList}
            />
            <div className="text-center">
              <ScanCodeConsultButton
                buttonText={automotiveNewYaml?.software_buttonText}
                qrcode={automotiveNewYaml?.software_qrcode}
              />
            </div>
          </SectionSubstanceBody>
        </SectionSubstance>
        {/* section 4 */}
        <SectionSubstance
          style={{ paddingBottom: 0 }}
          className={styles.CourseCard}
          titleMb={automotiveNewYaml?.tools_title_mb}
        >
          <CourseCard
            title={automotiveNewYaml?.tools_title}
            thumb={automotiveNewYaml?.tools_thumb}
            buttonText={automotiveNewYaml?.tools_buttonText}
            buttonHref={automotiveNewYaml?.tools_buttonLink}
            descList={automotiveNewYaml?.tools_descList}
          />
        </SectionSubstance>
        {/* section 5 */}
        <SectionSubstance
          titleMb={automotiveNewYaml?.panorama_titleMb}
          title={automotiveNewYaml?.panorama_title}
          descText={automotiveNewYaml?.panorama_desc}
        >
          <Panorama
            altTitle={automotiveNewYaml?.panorama_title}
            btnText={automotiveNewYaml?.panorama_btnText}
            btnCode={automotiveNewYaml?.panorama_btnCode}
            imgUrl={automotiveNewYaml?.panorama_imgUrl}
            btnTextTwo={automotiveNewYaml?.panorama_btnText_two}
            btnTextTwoUrl={automotiveNewYaml?.panorama_btnText_two_url}
          />
        </SectionSubstance>
        {/* section 6 */}
        <SectionSubstance
          className={classnames('bg-[#F6F9FF]', styles.partner)}
          titleMb={automotiveNewYaml?.partner_titleMb}
          descText={automotiveNewYaml?.partner_desc}
          title={automotiveNewYaml?.partner_title}
        >
          <div className="lg:w-[996px] mt-[30px] lg:mx-auto lg:mt-[40px] relative">
            <img src={automotiveNewYaml?.partner_voice_imgUrl} className="hidden lg:block" alt="" />
            <img
              style={{
                boxShadow: '0px 2px 20px 0px rgba(153, 169, 191, 0.18)',
              }}
              src={automotiveNewYaml?.partner_voice_imgUrlMb}
              className="block lg:hidden"
            />
            <span className="absolute  hidden w-[6.4rem] h-[5rem] bottom-[2rem] right-[2rem]">
              <img src={automotiveNewYaml?.partner_voice_icon_imgUrlMb} />
            </span>
          </div>
          {/* <PartnerCard className="pt-[2rem] lg:pt-[20px]" dataSource={automotiveNewYaml?.partner_dataList} /> */}
          <div className="text-center mt-[3rem] lg:mt-[40px]">
            <ScanCodeConsultButton
              imgBottomText={automotiveNewYaml?.partner_imgBottomText}
              buttonText={automotiveNewYaml?.partner_buttonText}
              qrcode={automotiveNewYaml?.partner_qrcode}
            />
          </div>
        </SectionSubstance>
        {/* section 7 更多干货 */}
        <SectionSubstance
          style={{ paddingBottom: 0 }}
          titleMb={automotiveNewYaml?.cargo_titleMb}
          title={automotiveNewYaml?.cargo_title}
        >
          <CargoCard
            className={classnames('lg:pt-[40px] pt-[2rem]', styles.CargoCard)}
            dataSource={automotiveNewYaml?.cargo_dataList?.map((item) => ({
              ...item,
              imgUrl: item?.imgUrl,
              imgUrlMb: item?.imgUrlMb,
            }))}
          />
        </SectionSubstance>
        {/* 了解神策产品 */}
        <SectionSubstance title={automotiveNewYaml?.product_title} titleMb={automotiveNewYaml?.product_titleMb}>
          <ProductCard
            className="lg:pt-[40px] pt-[2rem]"
            dataSource={automotiveNewYaml?.product_dataList?.map((item) => ({
              ...item,
              imgUrl: item?.imgUrl,
              imgUrlMb: item?.imgUrl,
            }))}
          />
        </SectionSubstance>
        {/* 尾部 */}
        <ArticleFooter
          className={styles.automotiveNewFooter}
          title={automotiveNewYaml?.digitization_title}
          gameDsc={automotiveNewYaml?.digitization_desc}
          btnText={automotiveNewYaml?.digitization_btnText}
          btnHref={automotiveNewYaml?.digitization_btnHref}
          orderText={automotiveNewYaml?.digitization_orderText}
          orderHref={automotiveNewYaml?.digitization_orderHref}
        />
      </main>
    </>
  )
}

export default AutomotiveNew
